<template>
  <div class="container">
    <!--      <div class="item" v-if="type === '1'">-->
    <!--         交易所:&nbsp;<el-select v-model="bourse" size="mini" placeholder="请选择交易所" style="width:200px;">-->
    <!--             <el-option value="1" label="1"></el-option>-->
    <!--         </el-select>-->
    <!--      </div>-->
    <!--      <div class="item" v-if="type === '2'">-->
    <!--         合约代码: &nbsp;<el-input v-model="code" placeholder="请输入合约代码" size="mini" style="width:200px;"></el-input>-->
    <!--      </div>-->
    <div>
      <span class="demonstration" v-if="componentType !== 'capitalSharesTion'" style="float: right;">
        <el-tooltip class="item" effect="dark" content="选择日期查询历史(默认查询当日)" placement="top">
          <i style="color: #3B6EFB;display: inline-block;margin-right: 10px" class="el-icon-warning"></i>
        </el-tooltip>
        <el-date-picker @calendar-change="handleChange" clearable size="mini" :default-value="defaultDates"
          :disabledDate="disablesDate" v-model="datePicker" type="daterange" @change="DateChange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>

        <!-- 查询历史 -->
      </span>

    </div>
    <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage"
      :total="total" @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="colums"
      :tableData="tableData"></spread-table>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, reactive, toRefs, watch, ref } from "vue"
import { Data, OperationDayData } from "./index.js"
import CommonTable from "../Table";
import { JudgmentComponents } from "../tools.js"
import SpreadTable from '@/components/SpreadTable'
import { dealDate, dealTableParame } from "../../../course-manage/trade-manage/tradeMethods";
import {
  findCashFlow,
  findDelegation,
  orderTransactionInformation
} from "../../../course-manage/trade-manage/tradeApi";
import { onBeforeRouteUpdate } from "vue-router";
import { getTableList } from "../../../course-manage/group-manage/groupApi";
import { dealList } from "../../../course-manage/group-manage/groupMethods";

export default {
  name: 'OperationDay',
  components: {
    CommonTable,
    SpreadTable
  },
  /**
   * @param type
   * 1 股票
   * 2 期货
   * 3 债券
   * @param componentType
   * TransactionDay 当日成交
   * DelegsignmentDay 当日委托
   */
  props: {
    type: { type: String, default: '1' },
    componentType: { type: String }
  },
  setup(props) {
    const { type, componentType } = props
    const data = reactive({
      bourse: '', //交易所
      code: '',//期货
      colums: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      tableHeight: 550,
      datePicker: '',
      startTime: '',
      endTime: '',
      defaultDates: new Date()
    })
    const disablesDate = (time) => {


      if (pickDay.value) {
        const one = 90 * 24 * 3600 * 1000
        const minTime = pickDay.value.getTime() - one
        const maxTime = pickDay.value.getTime() + one
        return time.getTime() < minTime || time.getTime() > maxTime || time.getTime() > new Date()
        // if(time.getTime() >pickDay.value.getTime()+ 30 * 8.64e7){
        // return true;
        // }
      } else {
        if (time.getTime() > new Date()) {
          return true;
        }
      }


    };
    onBeforeMount(() => {
    })

    onMounted(() => {
      data.pageSize = 10
      data.currentPage = 1
      //undefined
      data.startTime = undefined
      data.endTime = undefined
      //getThreeDaysAgo(),getToday()
      let e = getToday()
      let s = getWeekDaysAgo()
      data.datePicker = [s, e]
      data.startTime = new Date(s + " 00:00:00").getTime() / 1000
      data.endTime = new Date(e + " 00:00:00").getTime() / 1000
      let type = props.type === '' ? '1' : props.type
      const { result } = JudgmentComponents(type, props.componentType, Data)
      data.colums = result
      getTableData()
    })

    onBeforeRouteUpdate((to) => {
      data.pageSize = 10
      data.currentPage = 1
      data.startTime = undefined
      data.endTime = undefined
      data.datePicker = ''
      let type = props.type === '' ? '1' : props.type
      const { result } = JudgmentComponents(type, props.componentType, Data)
      data.colums = result

      getTableData()
    })

    watch([() => props.type, () => props.componentType], ([newType, newComponentType], [prvnewType, prenewComponentType]) => {
      const { result } = JudgmentComponents(newType, newComponentType, Data)
      data.colums = result
      data.currentPage = 1
    }, { immediate: true })



    // getTableData 获取table 数据
    let getTableData = () => {
      // 成交接口
      if (props.componentType === 'TransactionDay') {
        data.loading = true
        let types = props.type !== '3' ? 0 : 10
        let parame = {
          acid: JSON.parse(sessionStorage.getItem('acid')),
          // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
          type: props.type === '' ? '1' : props.type,
          exchange: types,
          startTime: data.startTime,
          endTime: data.endTime + 86399,
          pageSize: data.pageSize,
          page: data.currentPage,
        }
        orderTransactionInformation(parame).then(res => {
          if (res.data.code === '200') {
            let newRes = res.data.data
            if (data.startTime) {
              data.tableData = newRes.orderTransactions.list
              data.total = newRes.orderTransactions.total
            } else {
              data.tableData = newRes.orderTransactions.list
              data.total = newRes.orderTransactions.total
            }
            dealDate(data.tableData)
            dealTableParame(data.tableData)
            setTimeout(() => {
              data.loading = false
            }, 300)
          }
        })
      }
      // 委托接口
      if (props.componentType == 'DelegsignmentDay') {
        if (!data.datePicker && data.datePicker != null) {
          let e = getToday()
          let s = getWeekDaysAgo()
          data.datePicker = [s, e]
          data.startTime = new Date(s + " 00:00:00").getTime() / 1000
          data.endTime = new Date(e + " 00:00:00").getTime() / 1000
        }

        data.loading = true
        let parame = {
          acid: JSON.parse(sessionStorage.getItem('acid')),
          // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
          startTime: data.startTime,
          endTime: data.endTime == null ? null : (data.endTime + 86399),
          type: props.type === '' ? '1' : props.type,
          pageSize: data.pageSize,
          page: data.currentPage,
        }
        findDelegation(parame).then(res => {
          if (res.data.code === '200') {
            let newRes = res.data.data
            data.tableData = newRes.delegationPageInfo.list
            data.total = newRes.delegationPageInfo.total
            dealTableParame(data.tableData)
            setTimeout(() => {
              data.loading = false
            }, 300)
          }
        })
      }
      // 资金流
      if (props.componentType === 'HistoryTransaction') {
        data.loading = true
        let types = props.type === '3' ? 10 : 0
        let parame = {
          acid: JSON.parse(sessionStorage.getItem('acid')),
          // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
          exchange: types,
          startTime: data.startTime,
          endTime: data.endTime + 86399,
          pageSize: data.pageSize,
          page: data.currentPage,
        }
        findCashFlow(parame).then(res => {
          if (res.data.code === '200') {
            let newRes = res.data.data
            if (data.startTime) {
              data.tableData = newRes.ttradeCashflows.list
              data.total = newRes.ttradeCashflows.total
            } else {
              data.tableData = newRes.ttradeCashflowtodays.list
              data.total = newRes.ttradeCashflowtodays.total
            }
            dealDate(data.tableData, ['time'])
            setTimeout(() => {
              data.loading = false
            }, 300)
          }
        })
      }

      // 资金股份
      if (props.componentType === 'capitalSharesTion') {
        data.loading = true
        let param = {
          acid: JSON.parse(sessionStorage.getItem('acid')),
          type: parseInt(props.type),
          pageSize: data.pageSize,
          page: data.currentPage,
        }
        getTableList(param).then(res => {
          if (res.data.code === '200') {
            let newRes = res.data.data
            data.tableData = dealList(newRes.portfolioManagements.list)
            data.total = newRes.portfolioManagements.total
            setTimeout(() => {
              data.loading = false
            }, 300)
          }
        })
      }
    }

    let getToday = () => {
      //获取当前日期
      let myDate = new Date();
      let nowY = myDate.getFullYear();
      let nowM = myDate.getMonth() + 1;
      let nowD = myDate.getDate();
      let endDate =
        nowY +
        "-" +
        (nowM < 10 ? "0" + nowM : nowM) +
        "-" +
        (nowD < 10 ? "0" + nowD : nowD); //当前日期
      return endDate;
    };
    let getWeekDaysAgo = () => {
      let myDate = new Date();
      let lw = new Date(myDate - 1000 * 60 * 60 * 24 * 7);
      let lastY = lw.getFullYear();
      let lastM = lw.getMonth() + 1;
      let lastD = lw.getDate();
      let startData =
        lastY +
        "-" +
        (lastM < 10 ? "0" + lastM : lastM) +
        "-" +
        (lastD < 10 ? "0" + lastD : lastD);
      return startData;
    };

    let sizeChange = (val) => {
      data.pageSize = val
      getTableData()
    }
    let currentChange = (val) => {
      data.currentPage = val
      getTableData()
    }
    let DateChange = (val) => {

      data.pageSize = 10
      data.currentPage = 1
      if (val === null) {
        data.startTime = null
        data.endTime = null
      } else {
        data.startTime = Date.parse(val[0]) / 1000
        data.endTime = Date.parse(val[1]) / 1000
      }
      getTableData()
    }
    const pickDay = ref();
    const handleChange = (dates) => {
      let hasSelectDate = dates !== null && dates.length > 0
      pickDay.value = hasSelectDate ? dates[0] : null

    };
    //限制选择时间范围
    // let handleChange = (time) => {
    //   if (!data.startTime) {
    //     return false;
    //   }
    //   const minTime = new Date(datePicker.value).getTime()
    //   const maxTime = new Date(selectData.value).getTime() + timeRange * 365
    //   if (tempTime < maxTime) {
    //     return time.getTime() < minTime || time.getTime() > tempTime
    //   }
    //   return time.getTime() < minTime || time.getTime() > maxTime
    //   // if (props.between) {
    //   //   const con1 =  new Date(pickDay.value).getTime() - props.between * 24 * 60 * 60 * 1000;
    //   //   const con2 = new Date(pickDay.value).getTime() +  props.between * 24 * 60 * 60 * 1000;
    //   //   return time < con1 || time > con2;
    //   // }
    // }






    return {
      disablesDate,
      sizeChange,
      currentChange,
      DateChange,
      handleChange,
      ...toRefs(data),
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
